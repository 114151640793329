<template lang="pug">
v-container
    v-card(light)
        v-data-table(:headers='columns',
            :items='rows',
            single-expand=false,
            item-key='name',
            show-expand,
            dense,
        )

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
    
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'sattemplates',
            message: '',
            busy: false,

            templates: [],
            library: [],
            types: [],
            tags: [],
            options: [],

            columns: [
                {
                    text: 'Name',
                    sortable: true,
                },
                {
                    text: 'Description',
                    sortable: true,
                    filterable: true,
                }
            ]
        };
    },
    methods: {
        getTemplateData () {
            this.socketEmit('getTemplateData', null, (results) => {
                this.templates = results.templates,
                this.library = results.library;
                this.types = results.types;
                this.tags = results.tags;
                this.options = results.options;
            });
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        rows () {
            return [];
        }
    },
    watch: {

    },
    mounted () {
        this.getTemplateData();
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">

</style>
